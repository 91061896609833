import React from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Academy } from "@aps-management/primapp-common";
import { useQuery } from "@tanstack/react-query";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { unstable_Box as Box } from "@material-ui/core/Box";
import { Grid, Button, Typography, Chip } from "@material-ui/core";
import {
  MdOutlineAccountCircle,
  MdOutlineBook,
  MdOutlineTimer,
} from "react-icons/md";
/* */
import { Screen } from "_components/core";
import masterClient from "_utils/masterClient";
import { CalendarDay } from "_components/elements";
import moment from "moment";
/* */
import Header from "../_Header";

const getminMaxColor = (min, max) => {
  switch (true) {
    case min >= max:
      return "rgba(217, 89, 89, 1)";
    case min >= max / 2:
      return "rgba(239, 116, 39, 1)";
    default:
      return "rgba(61, 195, 77, 1)";
  }
};
/* */
const styles = (theme) => ({
  players: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(61, 195, 77, 1)",
    cursor: "pointer",
  },
  slots: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(132, 189, 227, 1)",
    cursor: "pointer",
  },
});
const Enter = (props) => {
  const history = useHistory();
  const { golf, account, classes } = props;
  const { id } = useParams();

  const { data: lesson, error: lessonErrors } = useQuery(
    ["academy-lesson", id],
    () =>
      Academy.api.getLesson(masterClient, {
        id,
      }),
    {
      enabled: !!id,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
      initialData: () => null,
    }
  );

  const { data: entryList, error: registrationErrors } = useQuery(
    ["academy-lesson-registrations", lesson?.id],
    () =>
      Academy.api.getLessonRegistrations(masterClient, {
        lessonId: lesson?.id,
      }),
    {
      enabled: !!lesson?.id,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
      initialData: () => null,
    }
  );
  const entry = !!entryList?.find((res) => res.customer.id === account.id);

  const goToMyAcademy = () => {
    history.push(`/myacademy/${lesson?.id}`);
  };
  /* */
  const renderEntry = () => {
    if (!entry) return null;
    return (
      <React.Fragment>
        <Typography variant="body1">
          {'Vous êtes déjà inscrit à ce cours.'}
        </Typography>
        <br />
        <Button color="secondary" variant="outlined" onClick={goToMyAcademy}>
          {"Voir mon inscription"}
        </Button>
      </React.Fragment>
    );
  };

  const renderSlotsList = () => {
    if (!lesson) return null;
    const { slotCount } = lesson;
    return (
      <Box mt={3} borderColor="#ddd">
        <Box display="flex" flexDirection="row">
          <MdOutlineBook size={24} />
          &nbsp;
          <Typography gutterBottom variant="body1">
            {"Liste des créneaux :"}
          </Typography>
          <Chip
            className={classes.slots}
            color="default"
            size="small"
            label={slotCount}
          />
        </Box>
        <Grid container spacing={8} style={{ paddingBottom: 12 }}>
          {lesson.coachSlots.map((s, key) => (
            <Grid item key={key}>
              <CalendarDay
                key={key}
                displayTime
                datetime={moment(s?.startsAt)}
                endTime={moment(s?.endsAt)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  /* */
  const renderEntryList = () => {
    if (!lesson) return null;
    const { attendeeCount, maxGroupSize } = lesson;
    if (!entryList?.length) return null;

    const columns = 3;
    const perColumn = Math.ceil(entryList.length / 3);

    return (
      <Box mt={3} borderColor="#ddd">
        <Box display="flex" flexDirection="row">
          <MdOutlineAccountCircle size={24} />
          &nbsp;
          <Typography gutterBottom variant="body1">
            {"Liste des inscrits :"}
          </Typography>
          <Chip
            className={classes.players}
            style={{
              backgroundColor: getminMaxColor(
                attendeeCount +
                  entryList.filter((e) => e.status === "WAITLISTED").length,
                maxGroupSize
              ),
            }}
            color="default"
            size="small"
            label={`${
              attendeeCount +
              entryList.filter((e) => e.status === "WAITLISTED").length
            } / ${maxGroupSize}`}
          />
        </Box>
        <Grid container>
          {[...Array(columns)].map((und, c) => (
            <Grid item xs={12} sm={4} key={c}>
              {entryList
                .filter((e) => e.status === "CONFIRMED")
                .slice(c * perColumn, c * perColumn + perColumn)
                .map((e, key) => (
                  <Typography
                    key={key}
                    variant="caption"
                  >{`${e.customer.lastName} ${e.customer.firstName}`}</Typography>
                ))}
            </Grid>
          ))}
        </Grid>
        {entryList.filter((e) => e.status === "WAITLISTED").length ? (
          <>
            <Box display="flex" flexDirection="row">
              <MdOutlineTimer size={24} />
              &nbsp;
              <Typography gutterBottom variant="body1">
                {"En liste d'attente :"}
              </Typography>
            </Box>
            <Grid container>
              {[...Array(columns)].map((und, c) => (
                <Grid item xs={12} sm={4} key={c}>
                  {entryList
                    .filter((e) => e.status === "WAITLISTED")
                    .slice(c * perColumn, c * perColumn + perColumn)
                    .map((e, key) => (
                      <Typography
                        key={key}
                        variant="caption"
                      >{`${e.customer.lastName} ${e.customer.firstName}`}</Typography>
                    ))}
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
      </Box>
    );
  };

  const goToNext = () => history.push(`/academy/${id}/entrants`);
  /* */
  const renderEnterButton = () => {
    const {
      maxGroupSize: limit,
      attendeeCount: counter,
      waitingListSize,
    } = lesson;

    // Inscription ouverte + joueur non inscrit
    const isOpened =
      !entry &&
      Academy.functions.isOpened(
        { ...lesson, openOnline: true },
        golf?.options
      );
    if (!isOpened) return null;

    // Limite atteinte
    if (limit && counter + waitingListSize >= limit) {
      return (
        <Button
          disabled
          fullWidth
          size="large"
          onClick={null}
          color="primary"
          variant="contained"
        >
          {"Complet"}
        </Button>
      );
    }
    return !entryList ? null : (
      <Box borderBottom={1}>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={goToNext}
        >
          {"S'inscrire"}
        </Button>
      </Box>
    );
  };
  const error = lessonErrors || registrationErrors;
  return (
    <Screen error={error} loading={!entryList} title="Académie - S'inscrire">
      {lesson && (
        <React.Fragment>
          <Header lesson={lesson} golf={golf} />
          {renderEntry()}
          {renderEnterButton()}
          {renderSlotsList()}
          {renderEntryList()}
        </React.Fragment>
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

/* */
const StyledComponent = withStyles(styles)(Enter);

export default connect(mapStateToProps)(withWidth()(StyledComponent));
