import React, { Fragment, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { App, Academy } from '@aps-management/primapp-common';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  List,
  Paper,
  Button,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { useMutation } from '@tanstack/react-query';
import { Screen } from '_components/core';
import masterClient from '_utils/masterClient';
import PaymentMethods from '_components/PaymentMethods';
/* */
import Header from '../_Header';

/* */
const styles = theme => ({
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  subItem: {
    paddingLeft: theme.spacing.unit * 2,
    fontSize: 14,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

/* */
const AcademyCart = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    golf,
    account,
    classes,
    enter,
    getEntryFees = true,
  } = props;
  const {
    lesson,
    entrants,
  } = enter;
  const { coachSlots = [] } = lesson || {};
  const [total] = [{ lesson: (lesson?.price * entrants.length)  }];
  const [waiting, setWaiting] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState(null);
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [paymentMethodOpts, setPaymentMethodOpts] = React.useState(null);
  const [areAcceptedTerms, setAreAcceptedTerms] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { cancellableUntilXHoursBefore } = {
    ...golf?.options?.academy?.onlineRestrictions?.global,
  } || 0;

  useEffect(() => {
    setPaymentMethods(App.functions.getPaymentMethods(account, golf, 'academy'));
  }, [account, golf]);

  useEffect(() => {
    if (paymentMethods) {
      setPaymentMethod(paymentMethods[0]);
    }
    setLoading(false);
  }, [paymentMethods]);



  /* */
  const handleChangePaymentMethod = (type, opts) => {
    setPaymentMethod(type);
    setPaymentMethodOpts(opts);
  }

  /* */
  const handleChangeAcceptTerms = checked => setAreAcceptedTerms(checked);

  /* */
  const propagatePaymentMethod = () => {
    // set payment method
    enter.entrants = entrants.map(e => ({
      ...e,
      lesson: {
        ...e.lesson,
        type: paymentMethod,
      },
    }));
  }


  const { mutate: bookLesson } = useMutation((data) => Academy.functions.asyncSetEntry(masterClient, data), {
    onMutate: async (variables) => {
      setWaiting(true);
      enter.transaction = variables.transaction;
    },
    onError: (error, variables, context) => {
      setWaiting(false);
      setError(error.message);
    },
    onSuccess: async (result, variables, context) => {
      goToVoucher();
    },
  });

  /* */
  const onConfirm = () => {
    setError(null);
    setWaiting(true);

    propagatePaymentMethod();

    const transaction = {
      amount: total.lesson,
      mode: paymentMethod,
      ...(paymentMethod === 2 && { walletId: paymentMethodOpts.wallet.id }),
    };
    return bookLesson({
      enter,
      golf,
      transaction,
    })
  }

  /* */
  const goToPayment = () => {
    propagatePaymentMethod();
    props.enter.status.push('cart');
    history.push(`/academy/${id}/payment`);
  }
  /* */
  const goToVoucher = () => {
    enter.status.push('payment');
    history.push(`/academy/${id}/voucher`);
  }

  /* */
  const renderPaymentMethod = () => {
    const totalAmount = total.lesson;
    const buttonTxt = `${(paymentMethod === 1 ? 'Valider' : 'Payer')}`
      + ` ${i18n.l('currency', totalAmount / 100)}`;
    if (!paymentMethods) return null;
      return (
        <Grid
          container
          spacing={8}
          justifycontent="center">
          <Grid
            item md={6} xs={12}
            className={classes.section}>
            <Typography
              paragraph
              component="h3"
              variant="h6">
              {'Votre moyen de paiement'}
            </Typography>
            <PaymentMethods
              amount={totalAmount}
              selected={paymentMethod}
              methods={paymentMethods}
              onChange={handleChangePaymentMethod}
              onChangeAcceptTerms={handleChangeAcceptTerms}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            className={classes.section}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              disabled={waiting || !areAcceptedTerms}
              onClick={paymentMethod === 0 ? goToPayment : onConfirm}>
              {waiting ? <CircularProgress color="inherit" size={24} /> : buttonTxt}
            </Button>
          </Grid>
            {cancellableUntilXHoursBefore > 0 && (
              <Grid
                item md={12} xs={12}
                className={classes.section}>
                <Typography
                  paragraph
                  style={{
                    color: 'rgba(28, 134, 22, 1)',
                    textAlign: 'center',
                  }}
                  component="p"
                  variant="body1">
                  {`Vous avez la possibilité d’annuler votre réservation gratuitement ${cancellableUntilXHoursBefore} heures avant la date du cours prévue. Pour plus d’informations, merci de vous rapprocher de l’accueil de votre golf.`}
                </Typography>
              </Grid>
            )}
        </Grid>
      );
  }

  /* */
  const renderNextButton = () => {
    if (!getEntryFees) return null;

    return renderPaymentMethod();
  };
  if (!lesson) return <Redirect to="/academy" />;
  return (
    <Screen
      error={error}
      loading={loading}
      title="Académie - S'inscrire"
      onBackPress={() => history.goBack()}>
      <Header lesson={lesson} golf={golf} />
      {getEntryFees && (
        <Fragment>
          <Typography variant="h6">
            {'Frais d\'inscription'}
          </Typography>
          <br />
          <Paper elevation={2}>
            <List disablePadding>
              {entrants.map(({ firstname, lastname }, i) => {
                const subtotal = lesson.price * coachSlots.length;

                return (
                  <ListItem key={i} divider>
                    <ListItemText
                      primary={`${firstname} ${lastname}`}
                      secondaryTypographyProps={{ className: classes.subItem }}
                      secondary={
                        <Fragment>
                          {coachSlots.length > 0 && coachSlots.map((slot, i) => (
                            <Fragment key={i}>
                              {`${moment(slot.startsAt).format('dddd DD MMM YYYY')} de ${moment(slot.startsAt).format('HH:mm')} à ${moment(slot.endsAt).format('HH:mm')}`}<br />
                            </Fragment>
                          ))}
                        </Fragment>
                      } />
                    <ListItemText
                      primary={subtotal === 0
                        ? i18n.t('terms.included')
                        : i18n.l('currency', lesson.price / 100)}
                      secondary={
                        <Fragment>
                          {coachSlots.length > 0 && coachSlots.map((slot, i) => (
                            <Fragment key={i}>
                              {lesson.price === 0
                            ? i18n.t('terms.included')
                            : ''}<br />
                            </Fragment>
                          ))}
                        </Fragment>
                      }
                      primaryTypographyProps={{ align: 'right' }}
                      secondaryTypographyProps={{ align: 'right' }} />
                  </ListItem>
                );
              })}
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary={'Total'}
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', (lesson.price * entrants.length) / 100)}
                </Typography>
              </ListItem>
            </List>
          </Paper>
          <br />
          <br />
          {renderNextButton()}
        </Fragment>
      )}
    </Screen>
  )
}

const mapStateToProps = ({
  app: {
    account,
    golf,
  },
  eventEnter: enter,
}) => ({ account, golf, enter });

const StyledComponent = withStyles(styles)(AcademyCart);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
