import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { helpers } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Paper,
  Divider,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { AddToCalendarDropdown } from '_components/elements';
import { TbCalendarPlus } from "react-icons/tb";

/* */
const styles = theme => ({
  voucher: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  paper: {
    paddingTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    textAlign: 'center',
  },
  body1: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  table: {
    paddingTop: theme.spacing.unit * 3,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  underline: { textDecoration: 'underline' },
  calButtonComponentStyles: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  calButtonLinkStyles: {
  }
});

/* */
const Voucher = (props) => {
  const {
    golf,
    classes,
    enter,
  } = props;
  const {
    comment,
    lesson,
    entrants,
    transaction,
  } = enter;
  const { coachSlots = [] } = lesson || {};
  const date = (lesson && lesson.startsAt);
  if (!lesson) return <Redirect to="/academy" />;

  /* */
  const renderLesson = () => {
    return lesson && (
      <React.Fragment>
        <Typography
          gutterBottom
          variant="h6"
          color="secondary">
          {lesson.name}
        </Typography>
        <Divider className={classes.divider} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <Typography variant="body1">
            {`Créneau${(coachSlots?.length > 1) ? 'x' : ''} :`}
          </Typography>
          <Box ml={2} textAlign="right">
            {coachSlots.map((slot, i) => (
              <Box flex={1} key={i} flexDirection="row" position="relative">
                <AddToCalendarDropdown
                  className={classes.calButtonComponentStyles}
                  linkProps={{
                    className: classes.calButtonLinkStyles,
                  }}
                  buttonText={(
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <Typography
                        noWrap
                        variant="body1">
                        {`${moment(slot.startsAt).format('DD/MM/YYYY')}: ${moment(slot.startsAt).format('HH:mm')}-${moment(slot.endsAt).format('HH:mm')}`}
                      </Typography>
                      <TbCalendarPlus size={20} style={{ paddingLeft: 4 }} />
                    </Box>
                  )}
                  event={{
                    description: '',
                    duration: moment.duration(moment(slot.endsAt).diff(moment(slot.startsAt))).asHours(),
                    endDatetime: moment(slot.endsAt).format('YYYYMMDDTHHmmssZ'),
                    location: `${golf.name}, ${golf.city}`,
                    startDatetime: moment(slot.startsAt).format('YYYYMMDDTHHmmssZ'),
                    title: lesson.name,
                    timezone: 'Europe/Paris',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <Typography variant="body1">
            {'Participant(s) :'}
          </Typography>
          <Box ml={2} textAlign="right">
            {entrants.map((en, i) => (
                <Typography
                  noWrap
                  key={i}
                  variant="body1">
                  {`${en.firstname} ${en.lastname}`}
                </Typography>
              ))}
          </Box>
        </Box>
        <Divider className={classes.divider} />
      </React.Fragment>
    );
  }
  /* */
  const renderPaymentInfo = () => {
    if (!transaction) return null;
    const text = transaction.mode === 1 ? 'Total à régler' : 'Total réglé';
    return (
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        className={classes.table}
        justifyContent="space-between">
        <Box>
          <Typography gutterBottom>{text}</Typography>
          <Typography color="textSecondary">
            {i18n.l('currency', transaction.amount / 100)}
          </Typography>
        </Box>
        {transaction.transactionNo && (
          <Box>
            <Typography gutterBottom>{'E-ticket'}</Typography>
            <Typography color="textSecondary">
              {transaction.transactionNo}
            </Typography>
          </Box>
        )}
        {transaction.mode === 1 && (
          <Typography color="textSecondary">
            {'Paiement sur place'}
          </Typography>
        )}
        {transaction.mode === 2 && (
          <Typography color="textSecondary">
            {'Paiement par compte client'}
          </Typography>
        )}
      </Box>
    );
  }
  return (
    <Screen layout="fixed" title="Académie - Confirmation d'inscription">
      <Typography
        paragraph
        variant="h4"
        align="center">
        {'Merci !'}
      </Typography>
      <Typography>
      </Typography>
      <div className={classes.voucher}>
        <Paper elevation={2} className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h5"
            component="h2"
            color="textSecondary">
            {golf.name}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            className={classes.underline}>
            {golf.email}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paper}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            component="h2"
            color="textSecondary">
            {helpers.string.ucfirst(i18n.l('date.formats.long', new Date(date)))}
          </Typography>
          <br />
          {comment && (
            <Box mb={2}>
              {comment.split('\n').map((line, key) => <Typography key={key}>{line}</Typography>)}
            </Box>
          )}
          {renderLesson()}
          <p className={classes.body1}>
            {'Un e-mail de confirmation vous a été envoyé à : '}
            <span className={classes.underline}>{entrants[0] && entrants[0].email}</span>
          </p>
          {renderPaymentInfo()}
          <Typography variant="subtitle2" style={{
            fontStyle: 'italic',
            fontSize: 12,
          }}>{'* Les tarifs peuvent varier en fonction de votre catégorie de joueur. Pour plus d’informations, merci de vous rapprocher de l\'accueil de votre golf.'}</Typography>
        </Paper>
      </div>
    </Screen>
  )
}

const mapStateToProps = ({ app: { golf }, eventEnter: enter }) => ({ golf, enter });

const StyledComponent = withStyles(styles)(Voucher);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
